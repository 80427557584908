main {
    background: #f0F0F0;
    grid-area: main;
    overflow-y: auto;
}

.main__container {
    padding: 20px 35px;
}


.table-condensed{
    font-size: 12px;
  }

table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

table td, table th {
    border: 1px solid #ddd;
    padding: 8px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ddd;
}

table th{
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #6474BC;
    color: white;
}

.cell {
    max-width: 50px; /* tweak me please */
    white-space : nowrap;
    overflow : hidden;
  }
  
  .expand-small-on-hover:hover {
    max-width : 200px; 
    text-overflow: ellipsis;
  }
  
  .expand-maximum-on-hover:hover {
    max-width : initial; 
  }

  @media screen and (max-width: 600px) {
    table {
      display: flex;  
      flex-wrap: nowrap;
      justify-content: center;
    }

    table tr {
      display: flex;
      flex-direction: column;
      }
    
    table td {
      white-space: nowrap;
    }
}