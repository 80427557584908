@import url(https://fonts.googleapis.com/css2?family=Lato:wgh@300;400;700&display=swap);
::-webkit-scrollbar{
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #6474BC;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaaa;
}

/* general styling */

* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-title{
  color: #2e4a66;
}

.text-lightblue {
  color: #469cac;
}

.text-red {
  color: #cc3d38;
}

.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}

.container {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.8fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";
}

@media only screen and (max-width: 978px){
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "nav"
      "main";
  }
}


main {
    background: #6474BC;
    grid-area: main;
    overflow-y: auto;
}

.main__container {
    padding: 20px 35px;
}

.main__title {
    display: flex;
    align-items: center;
}

.main__title > img {
    max-height: 100px;
    object-fit: contain;
    margin-right: 20px;
}

.main__greeting > h1 {
    font-size: 24px;
    color: #2e4a66;
    margin-bottom: 5px;
}

.main__greeting > p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
}

.main__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    gap: 30px;
    margin: 20px 0;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 70px;
    padding: 25px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
}

.card_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card_inner > span {
    font-size: 25px;
}

.charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 50px;
}

.charts__left {
    padding:25px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
}

.charts__left__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.charts__left__title > div > h1 {
    font-size: 24px;
    color: #2e4a66;
    margin-bottom: 5px;
}

.charts__left__title > div > p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
}

.charts__left__title > i {
    color: #ffffff;
    font-size: 20px;
    background: #8894CB;
    border-radius: 200px 0px 200px 200px;
    -moz-border-radius: 200px 0px 200px 200px;
    -webkit-border-radius: 200px 0px 200px 200px;
    border: 0px solid #000000;
    padding: 15px;
}

.charts__right {
    padding: 25px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 5px 5px 13px #ededed, -5px 5px 13px #ffffff;
}

.charts__right__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.charts__right__title > div > h1 {
    font-size: 24px;
    color: #2e4a66;
    margin-bottom: 5px;    
}

.charts__right__title > div > p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
}

.charts__right__title > i {
    color: #ffffff;
    font-size: 20px;
    background: #39447a;
    border-radius: 200px 0px 200px 200px;
    border: 0px solid #000000;
    padding: 15px;
}

.charts__right__cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 30px
}

.card1 {
    background: #8894CB;
    color: #ffffff;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
}

.card2 {
    background:  #8894CB;
    color: #ffffff;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
}

.card3 {
    background:  #8894CB;
    color: #ffffff;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
}

.card4 {
    background: #8894CB;
    color: #ffffff;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
}

@media only screen and (max-width: 855px) {
    .main__cards {
        grid-template-columns: 1fr;
        grid-gap: 10px;
        gap: 10px;
        margin-bottom: 0;
    }

    .charts {
        grid-template-columns: 1fr;
        margin-top: 30px;
    }
}
.navbar {
    background: #ffffff;
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid lightgray;
}

.nav_icon {
    display: none;
}

.nav_icon > i {
    font-size: 26px;
    color: #a5aaad;
}

.navbar__left > a {
    margin-right: 30px;
    text-decoration: none;
    color: #a5aaad;
    font-size: 15px;
    font-weight: 700;
}

.navbar__left .active_link {
    color: #265acc;
    border-bottom: 3px solid #265acc;
    padding-bottom: 12px;
}

.navbar__right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar__right > {
    margin-left: 20px;
    text-decoration: none;
}

.navbar__right > a > i {
    color: #a5aaad;
    font-size: 16px;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
    padding: 7px;
}

.navbar_right_new {
    display: flex;
    justify-content: center;
    align-items: center;    
}

.navbar_right_new > {
    margin-left: 20px;
    text-decoration: none;
}

.navbar_right_new > a > span {
    color: hsl(229, 40%, 56%);
    font-size: 18px;
    border-radius: 50px;
    background: #ffffff;    
    padding: 7px;
}

@media only screen and (max-width: 978px){
    .nav_icon {
        display: inline;
    }
}

@media only screen and (max-width: 480px){
    .navbar__left {
        display: none;
    }
}


#sidebar {
    background: #060b26;
    grid-area: sidebar;
    overflow-y: auto;
    padding: 20px;
    transition: all 0.5s;
}

.sidebar__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #f3f4f6;
    margin-bottom:30px;
}

.sidebar__img {
    display: flex;
    align-items: center;
}

.sidebar__title > div > img {
    width: 75px;
    object-fit: contain;
}

.sidebar__title > div > h1 {
    font-size: 18px;
    display:inline;
}

sidebar__title > i {
    font-size: 18px;
    display: none;
}

.sidebar__menu > h2 {
    color: #6474BC;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 0 10px;
    font-weight: 700;
}

.sidebar__link {
    justify-content: space-between;
    color: #f3f4f6;
    padding: 20px;    
    border-radius: 3px;
    margin-bottom: 5px;
}

.active_menu_link {
    background: rgba(62, 161, 117, 0.3);
    color: #6474BC;
}

.active_menu_link a {
    color: #6474BC !important;
}

.sidebar__link > a {
    text-decoration: none;
    color: #a5aaad;
    font-weight: 700;
}

.sidebar_link > i {
    margin-right: 10px;
    font-size: 18px;
}

.sidebar__logout {
    margin-top: 20px;
    padding: 10px;
    color: #e65061;
}

.sidebar__logout > {
    text-decoration: none;
    color: #e65061;
    font-weight: 700;
    text-transform: uppercase;
}

.sidebar__logout > i {
    margin-right: 10px;
    font-size: 18px;
}

.sidebar-responsive {
    display: inline !important;
    z-index: 9999 !important;
    left: 0 !important;
    position: absolute;
}

@media only screen and (max-width: 978px){
    #sidebar {
        display: none;
    }

    .sidebar__title > i {
        display: inline;
    }
}
main {
    background: #f0F0F0;
    grid-area: main;
    overflow-y: auto;
}

.main__container {
    padding: 20px 35px;
}


.table-condensed{
    font-size: 12px;
  }

table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

table td, table th {
    border: 1px solid #ddd;
    padding: 8px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ddd;
}

table th{
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #6474BC;
    color: white;
}

.cell {
    max-width: 50px; /* tweak me please */
    white-space : nowrap;
    overflow : hidden;
  }
  
  .expand-small-on-hover:hover {
    max-width : 200px; 
    text-overflow: ellipsis;
  }
  
  .expand-maximum-on-hover:hover {
    max-width : none;
    max-width : initial; 
  }

  @media screen and (max-width: 600px) {
    table {
      display: flex;  
      flex-wrap: nowrap;
      justify-content: center;
    }

    table tr {
      display: flex;
      flex-direction: column;
      }
    
    table td {
      white-space: nowrap;
    }
}
